import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
import versions from '/gen-src/versions.json';
export const pageTitle = "Thrift tutorial introduction";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "thrift-tutorial-introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#thrift-tutorial-introduction",
        "aria-label": "thrift tutorial introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thrift tutorial introduction`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#background"
        }}>{`Background`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#assumptions"
        }}>{`Assumptions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#prerequisites"
        }}>{`Prerequisites`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sample-service"
        }}>{`Sample service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#build-and-run-sample-service"
        }}>{`Build and run sample service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#try-writing-blog-service-yourself"
        }}>{`Try writing blog service yourself`}</a></li>
    </ul>
    <p>{`In this tutorial, you'll learn how to build an `}<a parentName="p" {...{
        "href": "https://thrift.apache.org/"
      }}>{`Apache Thrift`}</a>{` service with Armeria.
This tutorial is based on a `}<a parentName="p" {...{
        "href": "#sample-service"
      }}>{`sample service`}</a>{`, a minimal blog service, with which you can create, read, update, and delete blog posts.`}</p>
    <p>{`Follow this tutorial to write a service yourself or try `}<a parentName="p" {...{
        "href": "#build-and-run-sample-service"
      }}>{`running the sample service`}</a>{` right away.`}</p>
    <h2 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h2>
    <p>{`Before we start, let's swiftly go over Armeria's Thrift features:`}</p>
    <ul>
      <li parentName="ul">{`Transport over HTTP/1.1 or HTTP/2.`}</li>
      <li parentName="ul">{`Support for `}<inlineCode parentName="li">{`TBINARY`}</inlineCode>{`, `}<inlineCode parentName="li">{`TCOMPACT`}</inlineCode>{`, `}<inlineCode parentName="li">{`TJSON`}</inlineCode>{` and `}<inlineCode parentName="li">{`TTEXT`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/docs/server-thrift#serialization-formats"
        }}>{`serialization formats`}</a>{`.`}</li>
      <li parentName="ul">{`RPC level decorator support for both `}<a parentName="li" {...{
          "href": "/docs/client-decorator#implementing-decoratinghttpclientfunction-and-decoratingrpcclientfunction"
        }}>{`client`}</a>{`
and `}<a parentName="li" {...{
          "href": "/docs/server-decorator#implementing-decoratinghttpservicefunction-and-decoratingrpcservicefunction"
        }}>{`server`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Out of the box support for circuit breaker, retries, metric collection and more.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Full-fledged `}<a parentName="li" {...{
          "href": "/docs/server-docservice"
        }}>{`Thrift documentation service`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "assumptions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assumptions",
        "aria-label": "assumptions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assumptions`}</h2>
    <p>{`This tutorial assumes that you have:`}</p>
    <ul>
      <li parentName="ul">{`Experience in building services in Java`}</li>
      <li parentName="ul">{`Experience in Java frameworks for server-side programming`}</li>
      <li parentName="ul">{`Understanding of Apache Thrift and experience in implementing Thrift services`}</li>
    </ul>
    <h2 {...{
      "id": "prerequisites",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#prerequisites",
        "aria-label": "prerequisites permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Prerequisites`}</h2>
    <p>{`To run and develop the sample service, set your computer with the following requirements:`}</p>
    <ul>
      <li parentName="ul">{`JDK 11 or higher`}</li>
      <li parentName="ul">{`Thrift compiler: Install a Thrift compiler by either `}<a parentName="li" {...{
          "href": "https://thrift.apache.org/docs/install/"
        }}>{`building from source`}</a>{` or using a package manager like `}<inlineCode parentName="li">{`brew`}</inlineCode>{` or `}<inlineCode parentName="li">{`apt`}</inlineCode>{` depending on your environment.`}</li>
    </ul>
    <h2 {...{
      "id": "sample-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sample-service",
        "aria-label": "sample service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample service`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
      }}>{`sample service`}</a>{` provides you implementations of CRUD operations as specified below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Operation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Method`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`createBlogPost()`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Read`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`getBlogPost()`}</inlineCode>{`, `}<inlineCode parentName="td">{`listBlogPosts()`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`updateBlogPost()`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`deleteBlogPost()`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`The sample service code consists of the following folders and files.`}</p>
    <pre><code parentName="pre" {...{}}>{`thrift/
├─ src/
│  ├─ main/
│  │  ├─ java/
│  │  │  ├─ example.armeria.server.blog.thrift/
│  │  │  │  ├─ BlogClient.java
│  │  │  │  ├─ BlogServiceExceptionHandler.java
│  │  │  │  ├─ BlogServiceImpl.java
│  │  │  │  └─ Main.java
│  │  ├─ thrift/
│  │  │  └─ blog.thrift
│  └─ test/
│     └─ java/
│        └─ example.armeria.server.blog.thrift/
│           └─ BlogServiceTest.java
└─ build.gradle
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`  To keep our focus on Armeria, this tutorial and the sample service implement memory-based operations instead of using a database.`}</p>
    </Tip>
    <h2 {...{
      "id": "build-and-run-sample-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#build-and-run-sample-service",
        "aria-label": "build and run sample service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Build and run sample service`}</h2>
    <p>{`Try running the sample service and see the outcome of this tutorial.
Using Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation Service`}</a>{`, you can easily verify a server is running, receiving requests and sending responses.`}</p>
    <ol>
      <li parentName="ol">{`Download the code from `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
        }}>{`here`}</a>{`.`}</li>
      <li parentName="ol">{`Build the sample service using the Gradle Wrapper.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`$ ./gradlew build
`}</code></pre>
      </li>
      <li parentName="ol">{`Run the sample service again, using the Gradle Wrapper.`}
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`$ ./gradlew run
`}</code></pre>
      </li>
      <li parentName="ol">{`Open the Documentation service page on your web browser at `}<a parentName="li" {...{
          "href": "http://127.0.0.1:8080/docs"
        }}>{`http://127.0.0.1:8080/docs`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "try-writing-blog-service-yourself",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#try-writing-blog-service-yourself",
        "aria-label": "try writing blog service yourself permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Try writing blog service yourself`}</h2>
    <p>{`Use the sample service's `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/blob/main/tutorials/thrift/build.gradle"
      }}>{`build.gradle`}</a>{` file to start building the service from scratch.
Below is a part of the `}<inlineCode parentName="p">{`build.gradle`}</inlineCode>{` file for the sample service.
This tutorial uses `}<a parentName="p" {...{
        "href": "https://plugins.gradle.org/plugin/org.jruyi.thrift"
      }}>{`thrift-gradle-plugin`}</a>{` to compile Thrift IDL files and generate stubs.`}</p>
    <CodeBlock language="groovy" filename="build.gradle" mdxType="CodeBlock">{`
plugins {
  id "org.jruyi.thrift" version "0.4.2"
  id "application"
  id "idea"
  id "eclipse"
}\n
repositories {
  mavenCentral()
}\n
dependencies {
  implementation "com.linecorp.armeria:armeria:${versions['com.linecorp.armeria:armeria-bom']}"\n
  implementation "com.linecorp.armeria:armeria-thrift0.17:${versions['com.linecorp.armeria:armeria-bom']}"\n
  // Logging
  runtimeOnly "ch.qos.logback:logback-classic:${versions['ch.qos.logback:logback-classic']}"\n
  testImplementation "org.junit.jupiter:junit-jupiter:${versions['org.junit:junit-bom']}"\n
  testImplementation "com.linecorp.armeria:armeria-junit5:${versions['com.linecorp.armeria:armeria-bom']}"\n
  testImplementation "org.assertj:assertj-core:${versions['org.assertj:assertj-core']}"
}\n
application {
  mainClass.set('example.armeria.server.blog.thrift.Main')
}\n
tasks.withType(Test) {
  useJUnitPlatform()
}
`}</CodeBlock>
    <p>{`Start writing the blog service yourself by following the tutorial step by step:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/define-service"
        }}>{`Define a service`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/run-service"
        }}>{`Run a service`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/implement-create"
        }}>{`Implement CREATE`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/implement-read"
        }}>{`Implement READ`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/implement-update"
        }}>{`Implement UPDATE`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/implement-delete"
        }}>{`Implement DELETE`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      